/* eslint-disable import/no-unresolved */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './layout/MainLayout';
// import RouteWithSubRoutes from './RouteWithSubRoutes';

const Home = lazy(() => import('pages/home/Home'));
const About = lazy(() => import('pages/about/About'));
const Projects = lazy(() => import('pages/projects/Projects'));
const Store = lazy(() => import('pages/Store/Store'));
const HireMe = lazy(() => import('pages/HireMe/HireMe'));

// const routes = [
//   {
//     path: '/home',
//     component: Home,
//     exact: true,
//   },
//   {
//     path: '/about',
//     component: About,
//   },
//   {
//     path: '/projects',
//     component: Projects,
//   },
//   {
//     path: '/store',
//     component: Store,
//   },
//   {
//     path: '/',
//     component: HireMe,
//     exact: true,
//   },
// ];

const AppRouter = () => {
  return (
    <Router>
      <MainLayout>
        <Suspense fallback={<div className="lazy-loading">Loading...</div>}>
          {/* <Redirect exact from="/" to="/home" /> */}
          {/* {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))} */}
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/store" element={<Store />} />
            <Route path="/hire-me" element={<HireMe />} />
            <Route path="/" element={<Home />} exact />
          </Routes>
        </Suspense>
      </MainLayout>
    </Router>
  );
};

export default AppRouter;
