import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './MainLayout.less';

const { Header } = Layout;

// const rootRoutes = ['/', '/about', '/contact'];
// const aboutSubRoutes = ['/about/me', '/about/company'];
function MainLayout(props) {
  const { children } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const [linkName, setLinkName] = useState('');
  const [linkNameNum, setLinkNameNum] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setLinkName('Basem Munawwar');
    } else if (location.pathname === '/projects') {
      setLinkName('PROJECTS');
    } else if (location.pathname === '/hire-me') {
      setLinkName('Hire Me!');
    } else if (location.pathname === '/about') {
      setLinkName('About me');
    } else if (location.pathname === '/store') {
      setLinkName('STORE');
    }
  }, [location.pathname]);

  return (
    <Layout>
      <Header
        style={{ position: 'fixed', top: 0, zIndex: 1, width: '100%', height: 'unset', padding: 0 }}
      >
        <Menu
          id="navbar"
          theme="dark"
          mode="horizontal"
          selectable={true}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100px',
            padding: '0 44px',
            textTransform: 'uppercase',
          }}
          items={[
            {
              key: '0',
              label: (
                <Link to="/" style={{ paddingLeft: 0 }}>
                  <span
                    className="MenuText"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: 24,
                      padding: 0,
                    }}
                  >
                    BASEM MUNAWWAR
                  </span>
                </Link>
              ),
            },
            {
              key: '1',
              label: (
                <Link to="projects">
                  <span className="menu-item-link">Projects</span>
                </Link>
              ),
            },
            {
              key: '2',
              label: (
                <Link to="hire-me">
                  <span className="menu-item-link">Hire ME!</span>
                </Link>
              ),
            },
            {
              key: '3',
              label: (
                <Link to="about">
                  <span className="menu-item-link">About Me</span>
                </Link>
              ),
            },
            {
              key: '4',
              label: (
                <Link to="store">
                  <span className="menu-item-link">Store</span>
                </Link>
              ),
            },
          ]}
        />
        <div className="phoneNavbar">
          <p>{linkName}</p>
          <div className="hamburgerDiv" onClick={() => setOpenMenu(true)}>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
          </div>
          {openMenu && (
            <div className="phoneMenu">
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Link
                  to="/"
                  onClick={() => {
                    setLinkName('Basem Munawwar');
                    setOpenMenu(false);
                    setLinkNameNum(0);
                  }}
                >
                  <span
                    className="menu-item-link"
                    style={{
                      textDecorationLine: linkName === 0 && 'underline',
                      fontWeight: linkName === 0 && '700',
                    }}
                  >
                    Basem Munawwar
                  </span>
                </Link>
                <div className="hamburgerDiv" onClick={() => setOpenMenu(false)}>
                  <div className="hamburger"></div>
                  <div className="hamburger"></div>
                  <div className="hamburger"></div>
                  <div className="hamburger"></div>
                </div>
              </div>
              <Link
                to="projects"
                onClick={() => {
                  setLinkName('PROJECTS');
                  setOpenMenu(false);
                  setLinkNameNum(1);
                }}
              >
                <span
                  className="menu-item-link"
                  style={{
                    textDecorationLine: linkName === 1 && 'underline',
                    fontWeight: linkName === 2 && '700',
                  }}
                >
                  PROJECTS
                </span>
              </Link>
              <Link
                to="hire-me"
                onClick={() => {
                  setLinkName('Hire Me!');
                  setOpenMenu(false);
                  setLinkNameNum(2);
                }}
              >
                <span
                  className="menu-item-link"
                  style={{
                    textDecorationLine: linkName === 2 && 'underline',
                    fontWeight: linkName === 2 && '700',
                  }}
                >
                  Hire Me!
                </span>
              </Link>
              <Link
                to="about"
                onClick={() => {
                  setLinkName('About me');
                  setOpenMenu(false);
                  setLinkNameNum(3);
                }}
              >
                <span
                  className="menu-item-link"
                  style={{
                    textDecorationLine: linkName === 3 && 'underline',
                    fontWeight: linkName === 3 && '700',
                  }}
                >
                  About me
                </span>
              </Link>
              <Link
                to="store"
                onClick={() => {
                  setLinkName('STORE');
                  setOpenMenu(false);
                  setLinkNameNum(4);
                }}
              >
                <span
                  className="menu-item-link"
                  style={{
                    textDecorationLine: linkName === 4 && 'underline',
                    fontWeight: linkName === 4 && '700',
                  }}
                >
                  STORE
                </span>
              </Link>
            </div>
          )}
        </div>
      </Header>
      <Layout
        style={{
          paddingTop: '100px',
        }}
      >
        {children}
      </Layout>
    </Layout>
  );
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
export default MainLayout;
